import React, { useState } from 'react';
import { getReport } from '../api/api';
import Turnstile from 'react-turnstile';

let objectKey = '7809f7c2-7d0f-49b9-b68e-e955c332d4ab/7809f7c2-7d0f-49b9-b68e-e955c332d4ab.json';


function ViewPage() {
  const TURNSTILE_SITE_KEY = process.env.REACT_APP_TURNSTILE_SITE_KEY;

  const [showChallenge, setShowChallenge] = useState(false);

  function verifyTurnstile(){
    document.getElementById('view-stage1').disabled = true;
    setShowChallenge(true);
  }
  return (
    


    <div id="view-document">
      <h1>View Report</h1>
      <button id="view-stage1" onClick={verifyTurnstile}>View Report</button>
      <button id="view-stage2" hidden="true">Get Report from DB</button>
      <div id="view-widget">
      {showChallenge ? <Turnstile sitekey={TURNSTILE_SITE_KEY} onVerify={(token) => {
           document.getElementById('view-stage1').hidden = true;
           document.getElementById('view-stage2').hidden = false;
           setShowChallenge(false);
           document.getElementById('view-stage2').onclick = function() {
            if(!getReport(token, objectKey)){
              console.log('file doesnt exist');
            };
           };
      }} /> : <></>}
      </div>
    </div>



  );
}

export default ViewPage;
