import axios from 'axios';
import Ajv from 'ajv';

// when the page loads show a save button
// on click of the button display the turnstile widget
// once the onverify runs from turnstile rename the button to save to db
// after the save to db and the worker returns the name of the saved object to 
//  i.  disable the button for save to db. 
//  ii. show the object name so that a user can click/share  
// folder to save  
//  i. Questions 
//  ii. Answers

const documentSchema = {
  "type": "object",
  "required": ["documentVersion", "formType", "uuid"], 
  "properties": {
    "documentVersion": {"type": "string"},
    "formType": {"type": "string"},
    "uuid": {"type": "string"},
    "language" : {"type" : "string"},
    "questionPresentFlag" : {"type" : "boolean"},
    "encryptionFlag" : {"type" : "boolean"},
    "compressionFlag" : {"type" : "boolean"},
    "expiryDate" : {"type" : "string"},
    "fullPackage" : {"type" : "string"}
  }
};
const ajv = new Ajv.default({ allErrors: true });
const validateDocument = ajv.compile(documentSchema);





export const saveData = async ( private_access_token, data ) => {
  const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;
  try {
  
    console.log(API_BASE_URL);
    // validate the JSON document
    const isValid = validateDocument(data);
    console.log('isvalid : ' + isValid);
    if (!isValid){
      console.log('validation failed');
      return false;
    }

    const axiosInstance = axios.create({
      baseURL: API_BASE_URL,
      headers: {
        'Authorization': `Bearer ${private_access_token}`,
        'Content-Type': 'application/json'
      }
    });
    const response = await axiosInstance.put(`${API_BASE_URL}7809f7c2-7d0f-49b9-b68e-e955c332d4ab/7809f7c2-7d0f-49b9-b68e-e955c332d4ab.json`, data);
    return response.data;
  } catch (error) {
    console.error(error);
    throw new Error('Error saving data');
  }
};


export const getReport = async (private_access_token,objectKey) => {
  const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;
  try {
    const axiosInstance = axios.create({
      baseURL: API_BASE_URL,
      headers: {
        'Authorization': `Bearer ${private_access_token}`,
        'Content-Type': 'application/json'
      }
    });

    const response = await axiosInstance.get(`${API_BASE_URL}${objectKey}`);
    
    return response.data;
  } catch (error) {
    console.error(error);
    throw new Error('Error uploading file');
  }
};